import styled, { css } from "styled-components";
import { TextControl } from "@shared/styles/forms";
export const LookupInput = styled(TextControl) `
  position: relative;
  z-index: 30;
  padding-right: 5rem;
  width: 100%;
  
  ${({ isOpen }) => isOpen && css `
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}
`;
export const LookupDropdown = styled.div `
  background-color: rgba(24, 169, 93, 0.2);
  padding-top: 1rem;
  padding-bottom: 1rem;
  max-width: 100%;
  max-height: 35rem;
  overflow-x: hidden;
  overflow-y: scroll;
  
`;
export const LookupButton = styled.button `
  margin-top: 1rem; 
`;
// export const LookupButton = styled(StyledButton)`
//   width: 18.5rem;
// `;
export const LookupLoading = styled.div `
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
`;
export const Lookup = styled.div `
  position: relative;
  overflow: hidden;
  width: 100%;
`;
export const LookupWrapper = styled.div `
  align-items: flex-start;
  display: none;
  gap: 2rem;
  
  ${({ isOpen }) => isOpen && css `
    display: flex;
  `}
  
  @media screen and (max-width: 660px){
    flex-direction: column;
    align-items: flex-end;
    gap: 0.5rem;
  
    ${Lookup}{
      width: 100%;
      max-width: 100%;
      
      
      ${LookupInput}{
        width: 100%;
      }
    }
  }
`;
export const DetailsWrapper = styled.div `
  display: none;
  
  ${({ isOpen }) => isOpen && css `
    display: flex;
  `}
`;
export const Element = styled.div `
`;
