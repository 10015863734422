import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Name from "@components/Name";
import Email from "@components/inputs/Email";
import DateOfBirth from "@components/inputs/Datepicker/DateOfBirth";
import AddressLookup from "@components/AddressLookup";
import Phone from "@components/Phone";
function User({ prepend, show, title }) {
    const formattedPrepend = prepend ? `${prepend}_` : "";
    if (!show) {
        return null;
    }
    return (_jsxs("div", { className: "form__user", children: [title && (_jsx("h3", { className: "form__title", children: title })), _jsx(Name, { prepend: formattedPrepend }), _jsx(Email, { name: `${formattedPrepend}email`, placeholder: "Email Address" }), _jsx(Phone, { name: `${formattedPrepend}phone`, placeholder: "Phone Number" }), _jsx(AddressLookup, { name: `${formattedPrepend}address` }), _jsx(DateOfBirth, { name: `${formattedPrepend}dob`, placeholder: "Date of Birth" })] }));
}
// default props
User.defaultProps = {
    prepend: "",
    show: true,
    title: "",
};
export default User;
