class PageNavigation {
    constructor(element) {
        if (!(element instanceof HTMLElement)) {
            throw new TypeError("Invalid HTMLElement");
        }
        this.element = element;
        this.menu = this.element.querySelector(".menu-primary-navigation");
        this.mobileTriggers = this.element.querySelectorAll(".menu-toggle");
        this.initEvents();
    }
    initEvents() {
        this.initSubMenuEvents();
        this.initMobileTriggerEvents();
        this.initToggleSubMenu();
    }
    initSubMenuEvents() {
        var _a, _b, _c, _d;
        const submenus = (_a = this.element.querySelectorAll(".menu-item.menu-item-has-children")) !== null && _a !== void 0 ? _a : [];
        const subMenuButtons = (_b = this.element.querySelectorAll(".menu-item-has-children a")) !== null && _b !== void 0 ? _b : [];
        const backButtons = (_c = this.element.querySelectorAll(".submenu__back")) !== null && _c !== void 0 ? _c : [];
        const menuToggles = (_d = this.element.querySelectorAll(".menu-toggle")) !== null && _d !== void 0 ? _d : [];
        if (submenus.length === 0) {
            return;
        }
        // Add event listener for submenu opened
        Array.from(submenus).map((submenu) => {
            submenu.addEventListener("mouseenter", () => {
                this.openSubmenu(submenu);
            });
            submenu.addEventListener("mouseleave", () => {
                this.closeSubmenu(submenu);
            });
            // return new SubMenuNavigation(submenu);
            return submenu;
        }, this);
        Array.from(subMenuButtons).forEach((el) => {
            el.addEventListener("click", this.toggleSubMenu.bind(this));
        });
        Array.from(backButtons).forEach((el) => {
            el.addEventListener("click", this.closeSubMenu.bind(this));
        });
        Array.from(menuToggles).forEach((el) => {
            el.addEventListener("click", this.toggleMenu.bind(this));
        });
    }
    toggleMenu() {
        var _a;
        (_a = this.menu) === null || _a === void 0 ? void 0 : _a.classList.toggle("open");
        // this.closeOtherSubmenus(submenu);
    }
    openSubmenu(submenu) {
        submenu.classList.add("open");
        this.element.classList.add("open");
        // this.closeOtherSubmenus(submenu);
    }
    closeSubmenu(submenu) {
        submenu.classList.remove("open");
        this.element.classList.remove("open");
    }
    static toggleMobileNav(event) {
        event.preventDefault();
        const btn = event.currentTarget;
        const isAlreadyOpen = window.document.documentElement.classList.contains("site-menu-open");
        const text = btn.querySelector(".menu-toggle__text");
        const icon = btn.querySelector(".menu-toggle__icon");
        if (text) {
            text.innerText = isAlreadyOpen ? "Menu" : "Close";
        }
        if (icon) {
            icon.innerHTML = isAlreadyOpen ? "<use xlink:href=\"#icon--burger\">" : "<use xlink:href=\"#icon--close\">";
        }
        btn.classList.toggle("open");
        btn.classList.toggle("close");
        window.document.documentElement.classList.toggle("site-menu-open");
    }
    initMobileTriggerEvents() {
        if (this.mobileTriggers) {
            this.mobileTriggers.forEach((el) => {
                el.addEventListener("click", PageNavigation.toggleMobileNav.bind(this));
            });
        }
    }
    toggleSubMenu(event) {
        if (event.currentTarget && window.innerWidth < 1200) {
            const target = event.currentTarget;
            const parent = target.parentElement;
            // if the menu is already open then navigate to page else open submenu
            if (parent && parent.classList.contains("open")) {
                event.preventDefault();
            }
            if (parent) {
                const menu = parent.querySelector(".submenu");
                if (menu) {
                    menu.classList.toggle("open");
                    parent.classList.toggle("open");
                }
            }
        }
    }
    closeSubMenu(event) {
        const button = event.currentTarget;
        const menu = button === null || button === void 0 ? void 0 : button.closest(".submenu");
        menu === null || menu === void 0 ? void 0 : menu.classList.remove("open");
    }
    // private toggleSubMenu(event: Event) {
    //   const menuItem: HTMLElement = event.currentTarget as HTMLElement;
    //   const isMobile: boolean = window.innerWidth < 992;
    //
    //   // On mobile first click of menu item opens the sub-menu
    //   //  second click will navigate to page
    //   if (isMobile && !menuItem.classList.contains("open")) {
    //     event.preventDefault();
    //   }
    //
    //   (this.element.querySelectorAll(".open") ?? [])
    //     .forEach((el) => {
    //       el.classList.toggle("open", false);
    //     });
    //
    //   menuItem.classList.toggle("open");
    // }
    initToggleSubMenu() {
        var _a;
        ((_a = this.element.querySelectorAll(".menu-item-has-children > a")) !== null && _a !== void 0 ? _a : [])
            .forEach((el) => el.addEventListener("click", this.toggleSubMenu.bind(this)));
    }
}
export default PageNavigation;
