var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b;
import { addQueryArgs } from "@wordpress/url";
class LoqateLookup {
    static getAddressFromLoqate(postcode, container = "") {
        var _a, _b;
        if (!postcode.length)
            return Promise.reject(new Error("Invalid Postcode"));
        const APIKey = (_b = (_a = window.wordpress_globals) === null || _a === void 0 ? void 0 : _a.loqate_api_key) !== null && _b !== void 0 ? _b : "UC92-WX53-RY95-HP13";
        return LoqateLookup._makeRequestWithQueryString("Capture/Interactive/Find/v1.10/json3.ws", "POST", {
            Key: APIKey,
            Text: postcode,
            IsMiddleware: false,
            Origin: "GBR",
            Countries: "GBR",
            Container: container,
            Limit: "10",
            Language: "en-gb",
        });
    }
    static retrieveAddressDetailsFromLoqate(id) {
        var _a, _b;
        // UC92-WX53-RY95-HP13
        const APIKey = (_b = (_a = window.wordpress_globals) === null || _a === void 0 ? void 0 : _a.loqate_api_key) !== null && _b !== void 0 ? _b : "UC92-WX53-RY95-HP13";
        return LoqateLookup._makeRequestWithQueryString("Capture/Interactive/Retrieve/v1.00/json3.ws", "POST", {
            Key: APIKey,
            Id: id,
            Field1Format: "",
        });
    }
    static _makeRequestWithQueryString(url, type, data) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = addQueryArgs("", data).replace("?", "");
            try {
                const [response] = yield Promise.all([fetch(`${LoqateLookup.API_BASE}/${url}`, {
                        method: type || "GET",
                        redirect: "error",
                        mode: "cors",
                        referrer: "no-referrer",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/x-www-form-urlencoded",
                            "X-Requested-With": "XMLHttpRequest",
                        },
                        body: type !== "GET" ? params : null,
                    })]);
                if (response.ok) {
                    const contentType = response.headers.get("content-type");
                    if (contentType
                        && (contentType.indexOf("application/json") !== -1 || contentType.indexOf("text/javascript") !== -1)) {
                        const json = yield response.json();
                        return yield Promise.resolve(json);
                    }
                    throw new Error("Response malformed. Expeted valid JSON");
                }
                throw new Error("Response malformed. Expeted valid JSON");
            }
            catch (err) {
                return yield Promise.reject(err);
            }
        });
    }
}
LoqateLookup.API_BASE = "https://api.addressy.com";
LoqateLookup.API_KEY = (_b = (_a = window.wordpress_globals) === null || _a === void 0 ? void 0 : _a.loqate_api_key) !== null && _b !== void 0 ? _b : "";
export default LoqateLookup;
