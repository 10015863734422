import styled from "styled-components";
export const Title = styled.div `
  font: var(--paragraph-font);
  letter-spacing: var(--paragraph-letter-spacing);
  color: var(--input-text, #000);
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
`;
export const Desc = styled.div `
  font: var(--small-paragraph-font);
  letter-spacing: var(--small-paragraph-letter-spacing);
  color: var(--input-text, #000);
  opacity: 0.7;
  max-width: 100%;
  text-align: left;
  margin-top: .5rem;
`;
export const Element = styled.button `
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  box-shadow: none;
  outline: none;
  display: block;
  max-width: 100%;
  cursor: pointer;
  padding: 0.5rem;
  
  &:disabled {
    opacity: .4;
  }
`;
