import styled, { css } from "styled-components";
export const ControlGroup = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const ControlLabel = styled.label `
  font: var(--small-paragraph-font);
  letter-spacing: var(--small-paragraph-letter-spacing);
  margin-bottom: 1.2rem;

  ${({ hidden }) => hidden && css `
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  `}
`;
export const TextControl = styled.input `
  font: var(--form-control-font);
  letter-spacing: var(--form-control-letter-spacing);
  padding: var(--form-control-padding);
  border-radius: var(--form-control-border-radius);
  border: var(--form-control-border);
  outline: var(--form-control-outline);
  color: var(--input-text, #000);
  background-color: white;

  &::placeholder {
    font: var(--form-control-font);
    letter-spacing: var(--form-control-letter-spacing);
    opacity: .6;
    color: var(--input-text, #000);
  }

  ${({ invalid }) => invalid && css `
    border: .1rem solid var(--error);
  `}
`;
export const StyledButton = styled.button `
  appearance: none;
  -webkit-appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  border-radius: 1rem;
  border: .1rem solid var(--button-bg-colour, black);
  background-color: var(--button-bg-colour, black);
  color: var( --button-text-colour, white);
  font-weight: bold;
  font-size: 2rem;
  letter-spacing: -0.009rem;
  line-height: 2.4rem;
  text-align: center;
  cursor: pointer;
  transition: all .3s linear;
  text-decoration: none;
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  ${({ outline }) => outline && css `
      border: .1rem solid var(--button-bg-colour, black);
      background-color: transparent;
      color: var(--button-bg-colour, black);
      
      &:hover {
        background-color: var(--button-bg-colour, black);
        color: var(--button-text-colour, white);
      }
  `};
  
  ${({ black, outline }) => black && css `
    border: black;
    background-color: black;
    color: white;
    
    ${outline && css `
    
      border: .1rem solid black;
      background-color: transparent;
      color: black;
      
      &:hover {
        background-color: black;
        color: white;
      }
    `}
  `}
  
  ${({ dark, outline }) => dark && css `
    border: var(--button-bg-colour, black);
    background-color: var(--button-bg-colour, black);
    color: var(--button-text-colour, white);
    
    ${outline && css `
      border: .1rem solid var(--button-bg-colour, black);
      background-color: transparent;
      color: var(--button-bg-colour, black);
      
      &:hover {
        background-color: var(--button-bg-colour, black);
        color: var(--button-text-colour, white);
      }
    `}
  `}
`;
export const ToggleControl = styled.div `
  appearance: none;
  -webkit-appearance: none;
  border-radius: 1rem;
  height: 2rem;
  width: 4rem;
  background-color: var(--accent);
  position: relative;
  cursor: pointer;
  border: none;
  box-shadow: none;
  outline: none;
  transition: all .11s linear;
  
  &:before {
    content: "";
    display: block;
    height: 1.6rem;
    width: 1.6rem;
    background-color: #fff;
    position: absolute;
    top: .2rem;
    left: .2rem;
    border-radius: 50%;
    transition: all .11s linear;
  }
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  ${({ isOn }) => isOn && css `
    &:before {
      
      left: calc((100% - .2rem) - 1.6rem);
    }
  `}
`;
export const ToggleLabel = styled.label `
  font: var(--heading-four-font);
  letter-spacing: var(--heading-four-letter-spacing);
  cursor: pointer;
  padding: 0 1rem;
  
  &:first-child {
    padding-left: 0;
  }
  
  &:last-child {
    padding-right: 0;
  }
`;
export const FormError = styled.div `
  font: var(--small-paragraph-font);
  letter-spacing: var(--small-paragraph-letter-spacing);
  color: var(--error);
  display: block;
  margin-top: .5rem;
`;
export const UsageLevelWrapper = styled.div `
  display: block;
  margin-bottom: 3rem;
  
  ${({ hidden }) => hidden && css `
    display: none;
  `};
`;
