import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useController, useFormContext } from "react-hook-form";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
function Phone({ name, placeholder }) {
    const { control } = useFormContext();
    const { field: { onChange, value }, fieldState: { invalid, isTouched, error, }, } = useController({
        name,
        control,
    });
    const className = [
        "form__field",
        "form__phone"
    ];
    if (error) {
        className.push("form__invalid");
    }
    return (_jsxs("div", { className: className.join(" "), children: [_jsx("label", { className: "form__label sr-only", htmlFor: name, children: placeholder }), _jsx(Controller, { name: name, control: control, rules: {
                    required: "This field is required",
                    validate: (value) => isValidPhoneNumber(value) || "Invalid phone number"
                }, render: ({ field }) => (_jsx(PhoneInput, { placeholder: placeholder, required: true, defaultCountry: "GB", onChange: field.onChange, value: field.value })) }), error && _jsx("p", { className: "form__error", children: error.message })] }));
}
export default Phone;
