import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import RadioButton from "@components/RadioButton";
import { useController, useFormContext } from "react-hook-form";
function RadioGroup({ title, name, options }) {
    const { control } = useFormContext();
    const { field: { onChange, value }, fieldState: { invalid, error },
    // fieldState: { invalid, isTouched, isDirty },
    // formState: { touchedFields, dirtyFields },
     } = useController({
        name,
        control,
        rules: { required: true },
    });
    if (!options || !options.length) {
        return null;
    }
    const classList = ["form__field", "form__radio"];
    if (error) {
        classList.push("form__invalid");
    }
    return (_jsxs("div", { className: classList.join(" "), children: [_jsx("h5", { children: title }), options && options.map((option, index) => (_jsx(RadioButton, { onChange: (newValue) => {
                    console.log("Radio value: ", newValue);
                    onChange(newValue);
                }, id: index, name: name, label: option.label, value: option.value, checked: value === option.value }))), error && _jsx("p", { className: "form__error", children: error.message })] }));
}
export default RadioGroup;
