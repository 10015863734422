import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { formatAddressToString } from "./formatting";
import { Edit, Element, Text, Title } from "./styles";
function AddressDetails({ address, onEdit }) {
    const addressString = useMemo(() => formatAddressToString(address), [address]);
    const editTriggered = () => {
        onEdit();
    };
    if (!addressString) {
        return null;
    }
    return (_jsxs(Element, { children: [_jsx(Title, { children: "Address" }), _jsx(Text, { children: addressString }), _jsx(Edit, { type: "button", onClick: editTriggered, children: "Edit Address" })] }));
}
export default AddressDetails;
