export const getDayOptions = () => [...Array(31)].map((x, i) => ({ value: `${i + 1}`, label: `${i + 1}` }));
export const getMonthOptions = () => {
    const currentDate = new Date();
    return [...Array(12)].map((_, i) => {
        const month = new Date(currentDate.getFullYear(), i, 1).toLocaleString("default", { month: "short" });
        return { value: `${(i + 1)}`.padStart(2, "0"), label: month };
    });
};
export const getYearOptions = () => {
    const currentDate = new Date().getFullYear();
    const years = [];
    // eslint-disable-next-line no-plusplus
    for (let i = currentDate; i > (currentDate - 120); i--) {
        years.push({ value: `${i}`, label: `${i}` });
    }
    return years;
};
