import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Desc, Element, Title } from "./styles";
function LookupDropdownItem({ value, onAddressSelected, onContainerSelected, disabled, }) {
    const { Description, Id, Text, Type, } = value;
    const label = useMemo(() => {
        if (Type === "Container") {
            return (_jsxs(_Fragment, { children: [_jsx(Title, { children: Text }), _jsx(Desc, { children: Description.replace("- ", "") })] }));
        }
        return _jsx(Title, { children: Text });
    }, [Type, Text, Description]);
    const clickEvent = (ev) => {
        ev.preventDefault();
        if (Type === "Container") {
            return onContainerSelected(Id);
        }
        return onAddressSelected(Id);
    };
    return (_jsx(Element, { disabled: disabled, type: "button", onClick: clickEvent, children: label }));
}
LookupDropdownItem.defaultProps = {
    disabled: false,
};
export default LookupDropdownItem;
