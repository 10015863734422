import styled from "styled-components";
export const SubmitWrapper = styled.div `
  display: flex;
  align-items: center;
  gap: 2rem;
  
  @media screen and (max-width: 660px){
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
`;
