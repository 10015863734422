import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dropdowns, Element, NavButton } from "@components/inputs/Datepicker/DatepickerHeader/styles";
import { getMonthOptions, getYearOptions } from "@shared/dateOfBirthOptions";
import Dropdown from "react-dropdown";
function DatepickerHeader({ monthDate, date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled, }) {
    const months = getMonthOptions();
    const years = getYearOptions();
    // const months = useMemo(getMonthOptions, []);
    // const years = useMemo(getYearOptions, []);
    const getSelectedMonth = () => {
        const formattedMonth = String(monthDate.getMonth() + 1).padStart(2, "0");
        return months.find(({ value }) => value === formattedMonth);
    };
    const getSelectedYear = () => years.find((opt) => opt.value === `${date.getFullYear()}`);
    return (_jsxs(Element, { children: [_jsx(NavButton, { type: "button", "aria-label": "Previous Month", title: "Previous Month", onClick: () => decreaseMonth(), isPrev: true, disabled: prevMonthButtonDisabled, children: _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 18 18", children: _jsx("polygon", { points: "17 9 10.46 2.5 9.44 3.54 14.21 8.28 1 8.28 1 9.72 14.21 9.72 9.44 14.49 10.46 15.5 17 9", fillRule: "evenodd", fill: "#2863ad" }) }) }), _jsxs(Dropdowns, { children: [_jsx(Dropdown, { options: months, value: getSelectedMonth(), onChange: (ev) => changeMonth(parseFloat(ev.value) - 1), placeholder: "Month", className: "numeric-labels sm" }), _jsx(Dropdown, { options: years, value: getSelectedYear(), onChange: (ev) => changeYear(parseFloat(ev.value)), placeholder: "Year", className: "numeric-labels sm" })] }), _jsx(NavButton, { type: "button", "aria-label": "Next Month", title: "Next Month", onClick: () => increaseMonth(), disabled: nextMonthButtonDisabled, children: _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 18 18", children: _jsx("polygon", { points: "17 9 10.46 2.5 9.44 3.54 14.21 8.28 1 8.28 1 9.72 14.21 9.72 9.44 14.49 10.46 15.5 17 9", fillRule: "evenodd", fill: "#2863ad" }) }) })] }));
}
export default DatepickerHeader;
