import styled, { css } from "styled-components";
export const Dropdowns = styled.div `
  display: flex;
  align-items: center;
  column-gap: 2rem;
`;
export const NavButton = styled.button `
  appearance: none;
  -webkit-appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.5rem;
  height: 4.5rem;
  background-color: transparent;
  border: none;
  margin: .166rem;
  position: relative;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    width: 3.8rem;
    height: 3.8rem;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    display: block;
    border-radius: 50%;
    transition: background-color .2s linear;
  }
  
  svg {
    height: 2rem;
    width: 2rem;
    position: relative;
    z-index: 10;
  }
  
  &:hover:before {
    background-color: #f0f0f0;
  }
  
  &:disabled {
    opacity: .4;
  }
  
  ${({ isPrev }) => isPrev && css `
    transform: rotate(180deg);
  `}
`;
export const Element = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 1rem;
`;
