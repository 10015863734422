var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { useController } from "react-hook-form";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { useEffect, useState } from "react";
import LoqateLookup from "@api/LoqateLookup";
import { formatAddressDetails, formatLoqateValues } from "@components/AddressLookup/formatting";
import LookupDropdownItem from "@components/LookupDropdownItem";
import LoadingIcon from "@components/LoadingIcon";
import AddressDetails from "@components/AddressDetails";
import testPostcode from "@shared/testPostcode";
import { FormError } from "@shared/styles/forms";
import { DetailsWrapper, Element, Lookup, LookupButton, LookupDropdown, LookupInput, LookupLoading, LookupWrapper, } from "./styles";
const searchLoqate = AwesomeDebouncePromise(LoqateLookup.getAddressFromLoqate, 1000);
const getAddressDetails = AwesomeDebouncePromise(LoqateLookup.retrieveAddressDetailsFromLoqate, 1000);
function AddressLookup({ name, control }) {
    const { field: { onChange, value }, fieldState: { error, invalid, isTouched }, } = useController({
        name,
        control,
        rules: { required: true },
    });
    const [inputValue, setInputValue] = useState("");
    const [dropdownValues, setDropdownValues] = useState([]);
    const [isWorking, setIsWorking] = useState(false);
    const [postcodeError, setPostcodeError] = useState(null);
    const getAddresses = (searchString, container = "") => __awaiter(this, void 0, void 0, function* () {
        const postcodeResults = yield searchLoqate(searchString, container);
        if (!postcodeResults.Items.length) {
            return Promise.reject(new Error("No Addresses Found"));
        }
        return formatLoqateValues(postcodeResults);
    });
    const lookupInputChanged = (ev) => {
        setInputValue(`${ev.currentTarget.value}`);
    };
    // const scrollDoc = useCallback(() => {
    //   if (document.scrollingElement) {
    //     document.scrollingElement.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    //   }
    // }, []);
    const initiateSearch = useCallback((id) => {
        setDropdownValues([]);
        setIsWorking(true);
        getAddresses(inputValue, id)
            .then((addressResponse) => {
            setIsWorking(false);
            if (addressResponse[0].Error) {
                setDropdownValues([{
                        Description: addressResponse[0].Description,
                        Id: "fake-99999",
                        Highlight: "test",
                        Text: addressResponse[0].Description,
                        Type: "Address",
                        value: "fake",
                        label: addressResponse[0].Description,
                    }]);
            }
            else {
                setDropdownValues(addressResponse);
            }
            // setTimeout(scrollDoc, 100);
        });
    }, [inputValue]);
    // scrollDoc
    const addressSelected = useCallback((id) => {
        setIsWorking(true);
        if (id === "fake-99999") {
            setIsWorking(false);
            onChange({
                address_1: "Loqate out of credits",
                address_2: "Loqate out of credits",
                city: "Loqate out of credits",
                state: "Loqate out of credits",
                country: "Loqate out of credits",
                postcode: "Loqate out of credits",
            });
        }
        else {
            getAddressDetails(id)
                .then((addressResponse) => {
                var _a;
                setIsWorking(false);
                const address = formatAddressDetails((_a = addressResponse.Items[0]) !== null && _a !== void 0 ? _a : null);
                onChange(address);
            });
        }
    }, [onChange]);
    const addressEdited = useCallback(() => {
        onChange(null);
    }, [onChange]);
    useEffect(() => {
        if (inputValue || inputValue.length) {
            if (testPostcode(inputValue)) {
                setPostcodeError(null);
                initiateSearch("");
            }
            else {
                setPostcodeError("Invalid Postcode");
            }
        }
    }, [initiateSearch, inputValue]);
    const hasValue = !!value;
    const isOpen = (dropdownValues && dropdownValues.length > 0);
    const classList = ["form__field", "form__address"];
    if (error || postcodeError) {
        classList.push("form__invalid");
    }
    return (_jsxs("div", { className: classList.join(" "), children: [_jsxs(Element, { children: [_jsx("input", { type: "hidden", name: "address[address_1]", value: value === null || value === void 0 ? void 0 : value.address_1 }), _jsx("input", { type: "hidden", name: "address[address_2]", value: value === null || value === void 0 ? void 0 : value.address_2 }), _jsx("input", { type: "hidden", name: "address[city]", value: value === null || value === void 0 ? void 0 : value.city }), _jsx("input", { type: "hidden", name: "address[state]", value: value === null || value === void 0 ? void 0 : value.state }), _jsx("input", { type: "hidden", name: "address[country]", value: value === null || value === void 0 ? void 0 : value.country }), _jsx("input", { type: "hidden", name: "address[postcode]", value: value === null || value === void 0 ? void 0 : value.postcode }), _jsx("input", { type: "hidden", name: "address[company]", value: value === null || value === void 0 ? void 0 : value.company }), _jsx(DetailsWrapper, { isOpen: hasValue, children: _jsx(AddressDetails, { address: value, onEdit: addressEdited }) }), _jsxs(LookupWrapper, { isOpen: !hasValue, children: [_jsxs(Lookup, { children: [_jsx(LookupInput, { type: "text", value: inputValue, onChange: lookupInputChanged, isOpen: isOpen, placeholder: "Enter your postcode" }), isWorking && (_jsx(LookupLoading, { children: _jsx(LoadingIcon, {}) })), isOpen && (_jsx(LookupDropdown, { children: dropdownValues.map((dropdownItem) => (_jsx(LookupDropdownItem, { disabled: isWorking, value: dropdownItem, onAddressSelected: addressSelected, onContainerSelected: initiateSearch }))) })), postcodeError && (_jsx(FormError, { children: _jsx("p", { className: "form__error", children: postcodeError }) }))] }), _jsx(LookupButton, { type: "button", className: "btn btn--outline btn--small", onClick: () => initiateSearch(""), children: "Find" })] })] }), error && _jsx("p", { className: "form__error", children: error.message })] }));
}
export default AddressLookup;
