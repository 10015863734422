import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useController, useFormContext } from "react-hook-form";
import DatePicker from "react-datepicker";
import DatepickerHeader from "@components/inputs/Datepicker/DatepickerHeader";
function DateOfBirth({ name, placeholder }) {
    const { control } = useFormContext();
    const { field: { onChange, value }, fieldState: { invalid, isTouched, error }, } = useController({
        name,
        control,
    });
    const className = [
        "form__field",
        "form__data",
    ];
    if (error) {
        className.push("form__invalid");
    }
    return (_jsxs("div", { className: className.join(" "), children: [_jsx("label", { className: "form__label sr-only", htmlFor: name, children: placeholder }), _jsx(Controller, { control: control, render: ({ field: { value, onChange } }) => (_jsx(DatePicker, { onChange: onChange, selected: value, dateFormat: "dd/MM/yyyy", yearDropdownItemNumber: 100, placeholderText: placeholder, renderCustomHeader: DatepickerHeader, renderDayContents: (day) => (_jsx("span", { children: day })), required: true })), name: name }), error && _jsx("p", { className: "form__error", children: error.message })] }));
}
export default DateOfBirth;
