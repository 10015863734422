import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useController, useFormContext } from "react-hook-form";
function Email({ name, placeholder }) {
    const { control } = useFormContext();
    const { field: { onChange, value }, fieldState: { invalid, isTouched, error, }, } = useController({
        name,
        control,
        rules: {
            required: "This field is required",
            pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format",
            },
        },
    });
    const className = [
        "form__field",
        "form__email",
    ];
    if (error) {
        className.push("form__invalid");
    }
    return (_jsxs("div", { className: className.join(" "), children: [_jsx("label", { className: "form__label sr-only", htmlFor: name, children: placeholder }), _jsx("input", { type: "email", name: name, placeholder: placeholder, required: true, onChange: onChange, value: value }), error && _jsx("p", { className: "form__error", children: error.message })] }));
}
export default Email;
