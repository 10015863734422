import styled from "styled-components";
export const Element = styled.div `
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 1.5rem 0 0;
`;
export const Title = styled.h4 `
  display: block;
  width: 100%;
  margin-bottom: 0rem;
`;
export const Text = styled.span `
  font: var(--paragraph-font);
  letter-spacing: var(--paragraph-letter-spacing);
`;
export const Edit = styled.button `
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  padding: 0;
  font: var(--paragraph-font);
  letter-spacing: var(--paragraph-letter-spacing);
  color: var(--primary);
  text-decoration: underline;
  border: none;
  cursor: pointer;
`;
