/* eslint-disable @typescript-eslint/naming-convention */
import { object, string, } from "yup";
export const addressSchema = object({
    address_1: string(),
    address_2: string(),
    city: string(),
    state: string(),
    country: string(),
    postcode: string(),
    company: string(),
});
export const formSchema = object({
    firstName: string().required("First name is required")
        .max(255, "First name must be less than 255 characters"),
    lastName: string().required("Last name is required")
        .max(255, "Last name must be less than 255 characters"),
    email: string().required("Email is required")
        .email("Please enter a valid email address")
        .max(255, "Email must be less than 255 characters"),
    phone: string().required("Phone number is required")
        .max(50, "Phone number must be less than 50 characters"),
    dob: string().required("Date of birth is required"),
    address: addressSchema.nullable().required("Address is required").default(null),
    registeringFor: string().required("Please select an option"),
    hasDementia: string().required("Please select an option"),
    other_firstName: string()
        .when("registeringFor", {
        is: "Someone else",
        then: (schema) => schema.required("First name is required")
            .max(255, "First name must be less than 255 characters"),
        otherwise: (schema) => schema.notRequired(),
    }),
    other_lastName: string()
        .when("registeringFor", {
        is: "Someone else",
        then: (schema) => schema.required("Last name is required")
            .max(255, "Last name must be less than 255 characters"),
        otherwise: (schema) => schema.notRequired(),
    }),
    other_email: string()
        .when("registeringFor", {
        is: "Someone else",
        then: (schema) => schema.required("Email is required")
            .email("Please enter a valid email address")
            .max(255, "Email must be less than 255 characters"),
        otherwise: (schema) => schema.notRequired(),
    }),
    other_phone: string()
        .when("registeringFor", {
        is: "Someone else",
        then: (schema) => schema.required("Phone number is required")
            .max(50, "Phone number must be less than 50 characters"),
        otherwise: (schema) => schema.notRequired(),
    }),
    other_dob: string()
        .when("registeringFor", {
        is: "Someone else",
        then: (schema) => schema.required("Date of birth is required"),
        otherwise: (schema) => schema.notRequired(),
    }),
    other_address: addressSchema.nullable()
        .when("registeringFor", {
        is: "Someone else",
        then: (schema) => schema.required("Address is required"),
        otherwise: (schema) => schema.notRequired(),
    })
        .default(null),
    medicalCondition: string()
        .when("hasDementia", {
        is: "no",
        then: (schema) => schema.required("Field is required"),
        otherwise: (schema) => schema.notRequired(),
    }),
});
