import { jsx as _jsx } from "react/jsx-runtime";
import { createRoot } from "react-dom/client";
import HerbertProtocolForm from "@widgets/HerbertProtocolForm";
const initForm = (el) => {
    const element = (_jsx("div", { className: "container-xsmall", children: _jsx(HerbertProtocolForm, {}) }));
    const root = createRoot(el);
    root.render(element);
};
window.addEventListener("DOMContentLoaded", () => {
    var _a;
    Array.from((_a = document.querySelectorAll(".section--form")) !== null && _a !== void 0 ? _a : []).forEach((el) => {
        initForm(el);
    });
});
