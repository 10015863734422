import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from "react";
import { FormProvider, useForm, } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import User from "@components/User";
import RadioGroup from "@components/RadioGroup";
import Text from "@components/inputs/Text";
import { formSchema } from "@widgets/HerbertProtocolForm/validation";
import { SubmitWrapper } from "@widgets/HerbertProtocolForm/styles";
function AgentCheckerForm() {
    const formRef = useRef(null);
    const methods = useForm({
        mode: "onBlur",
        resolver: yupResolver(formSchema),
        // shouldFocusError: true,
    });
    // const { register, handleSubmit } = useForm();
    const [data, setData] = useState("");
    const [errors, setErrors] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const onSubmit = (submittedData) => {
        const formData = new FormData();
        Object.keys(submittedData).forEach((key) => {
            const value = submittedData[key];
            if (!value)
                return;
            if (typeof value === "object") {
                Object.keys(value).forEach((innerKey) => {
                    const obj = Object.assign({}, value);
                    formData.append(`${key}.${innerKey}`, `${obj[innerKey]}`);
                });
            }
            else {
                formData.append(key, `${value}`);
            }
        });
        formData.append("action", "sign_up_submission");
        formData.append("_ajax_nonce", window.wordpress_globals.sign_up_nonce);
        fetch(window.wordpress_globals.ajax_url, {
            method: "POST",
            body: formData,
        })
            .then((response) => { return response.json(); })
            .then((responseData) => {
            console.log(responseData);
            setSubmitted(true);
        })
            .catch((error) => console.error("Error:", error));
        const submittedDataString = JSON.stringify(submittedData);
        console.log("[HerbertProtocolForm.tsx] Submitted data: ", submittedDataString);
        setData(submittedDataString);
    };
    console.log("[HerbertProtocolForm.tsx] errors: ", errors);
    const registeredFor = methods.watch("registeringFor");
    const showOtherUser = registeredFor === "Someone else";
    const hasDementia = methods.watch("hasDementia");
    const showHasDementia = registeredFor !== undefined;
    const showMedicalCondition = hasDementia === "no";
    console.log("[HerbertProtocolForm.tsx] registeredFor: ", registeredFor);
    console.log("[HerbertProtocolForm.tsx] errors: ", methods.formState.errors);
    return (_jsxs(FormProvider, Object.assign({}, methods, { children: [submitted && (_jsxs("div", { className: "form__success", children: [_jsx("h3", { className: "form__success-title", children: "Thank you for getting in touch!" }), _jsx("p", { className: "form__success-copy", children: "A member of our team will get in touch shortly luptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi." }), _jsx("div", { className: "form__success-btns", children: _jsx("a", { href: "/", className: "btn btn--primary", children: "Back to Home" }) })] })), !submitted && (_jsxs("form", { ref: formRef, noValidate: true, onSubmit: methods.handleSubmit((submittedData) => onSubmit(submittedData), (errors) => {
                    console.log("Form errors: ", errors);
                    let errorArray = [];
                    // get errors as string array
                    Object.keys(errors)
                        .forEach((key) => {
                        // @ts-ignore
                        const error = errors[key];
                        console.log("Error: ", error);
                        if (error.message && typeof error.message === "string") {
                            errorArray.push(error.message);
                        }
                    });
                    setErrors(errorArray);
                    const position = formRef.current ? formRef.current.getBoundingClientRect().top + document.documentElement.scrollTop - 100 : 0;
                    window.scrollTo({
                        top: position,
                        behavior: "smooth",
                    });
                    // scroll to top of the form
                }), children: [errors.length > 0 && (_jsx("div", { className: "form__field form__has-errors", children: _jsx("p", { children: "There are errors on the form. Please fix them before continuing." }) })), _jsx(User, { title: "Your details:" }), _jsx(RadioGroup, { title: "Are you registering for yourself or on behalf of someone else?", name: "registeringFor", options: [
                            {
                                label: "Registering for me",
                                value: "Myself",
                            },
                            {
                                label: "Registering for someone else",
                                value: "Someone else",
                            },
                        ] }), _jsx(User, { show: showOtherUser, prepend: "other", title: "Their details:" }), showHasDementia && (_jsx(RadioGroup, { title: "Confirm person you are registering for has dementia?", name: "hasDementia", options: [
                            {
                                label: "Yes",
                                value: "yes",
                            },
                            {
                                label: "No",
                                value: "no",
                            },
                        ] })), showMedicalCondition && (_jsx(Text, { name: "medicalCondition", placeholder: "If No, please state cognitive medical condition" })), _jsxs(SubmitWrapper, { className: "form__field form__submit", children: [_jsx("input", { onClick: () => console.log("Form error: ", methods.formState.errors), className: "btn btn--primary", type: "submit" }), _jsx("p", { children: _jsx("small", { children: "Marketing opt-inr sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore" }) })] })] }))] })));
}
export default AgentCheckerForm;
