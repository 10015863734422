/* globals jQuery, wordpress_globals */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import "../sass/theme.scss";
import $ from "jquery";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";
// @ts-ignore
import svgxhr from "webpack-svgstore-plugin/src/helpers/svgxhr";
import PageNavigation from "./modules/PageNavigation";
import CopyUrlToClipboard from "./modules/CopyUrlToClipboard";
import FeaturedNewsSwiper from "./modules/FeaturedNewsSwiper";
import SimpleSwiper from "./modules/SimpleSwiper";
import AnimatedScroll from "./modules/AnimatedScroll";
import "./modules/Form";
// Prefer creating new modules when appropriate, rather than adding functions here
// If you need something to be globally accessible, you can assign an imported module to this namespace
// I.E import Contact from './contact';
// window.namespace.Contact = Contact;
const bootstrap = {
    init() {
        var _a, _b, _c, _d, _e, _f, _g;
        this.helpers.initSvg();
        new AnimatedScroll();
        ((_a = document.querySelectorAll("#page-navigation")) !== null && _a !== void 0 ? _a : []).forEach((el) => new PageNavigation(el));
        ((_b = document.querySelectorAll(".copy-link")) !== null && _b !== void 0 ? _b : []).forEach((el) => new CopyUrlToClipboard(el));
        Array.from((_c = document.querySelectorAll(".featured-news")) !== null && _c !== void 0 ? _c : []).forEach((el) => new FeaturedNewsSwiper(el));
        Array.from((_d = document.querySelectorAll(".simple-swiper")) !== null && _d !== void 0 ? _d : []).forEach((el) => new SimpleSwiper(el));
        Array.from((_e = document.querySelectorAll(".section--scroll-slider")) !== null && _e !== void 0 ? _e : []).forEach((el) => {
            const scrollSliderInit = () => __awaiter(this, void 0, void 0, function* () {
                const ScrollSlider = yield import("./modules/ScrollSlider");
                ScrollSlider.default.create(el);
            });
            scrollSliderInit();
        });
        Array.from((_f = document.querySelectorAll(".section--home-header")) !== null && _f !== void 0 ? _f : []).forEach((el) => {
            const mouseButton = el.querySelector(".btn--mouse");
            if (mouseButton) {
                const initMouseButton = () => __awaiter(this, void 0, void 0, function* () {
                    const MouseButton = yield import("./modules/MouseButton");
                    MouseButton.default.create(mouseButton, el);
                });
                initMouseButton();
            }
        });
        Array.from((_g = document.querySelectorAll(".section--map .google-map")) !== null && _g !== void 0 ? _g : []).forEach((el) => {
            const initMap = () => __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c, _d, _e;
                console.log("[main.ts] initMap");
                const MapBlockSimple = yield import("./modules/MapBlockSimple");
                const lat = (_a = el.dataset.lat) !== null && _a !== void 0 ? _a : null;
                const lng = (_b = el.dataset.lng) !== null && _b !== void 0 ? _b : null;
                const marker = (_c = el.dataset.marker) !== null && _c !== void 0 ? _c : "https://maps.google.com/mapfiles/kml/paddle/blu-circle.png";
                if (lat && lng) {
                    MapBlockSimple.default.create(el, (_d = wordpress_globals.google_maps_api_key) !== null && _d !== void 0 ? _d : "", (_e = wordpress_globals.google_maps_style) !== null && _e !== void 0 ? _e : "", parseFloat(lat), parseFloat(lng), marker);
                }
            });
            initMap();
        });
        // Allow user to interrupt auto-scrolling
        $(document).bind("scroll mousedown wheel keyup", (e) => {
            if ((e.which || e.type === "mousedown" || e.type === "mousewheel") && document.scrollingElement) {
                $(document.scrollingElement).stop();
            }
        });
    },
    helpers: {
        requireAll(r) {
            r.keys().forEach(r);
        },
        initSvg() {
            svgxhr({
                filename: "./wp-content/themes/class/dist/sprite.svg", // URL to fetch
            });
        },
    },
};
document.addEventListener("DOMContentLoaded", () => {
    bootstrap.init();
});
// SVG Sprite Loader
bootstrap.helpers.requireAll(require.context("../images/icons/", true, /\.svg$/));
